import { toArray } from '../helpers';
export class EventRegister {
    constructor(stateCache) {
        this.stateCache = stateCache;
        this.subscribedEvents = new Map();
    }
    add(trigger, callback) {
        const triggerArray = toArray(trigger);
        this.subscribedEvents.set(triggerArray, callback);
        return () => this.subscribedEvents.delete(triggerArray);
    }
    run(updatedProps) {
        this.subscribedEvents.forEach((callback, trigger) => {
            // @ts-ignore
            if (Object.keys(updatedProps).some((key) => trigger.includes(key))) {
                callback(this.stateCache.current, this.stateCache.prev);
            }
        });
    }
}
