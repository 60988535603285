"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CODE_MISSING = 'CODE_MISSING';
const MESSAGE_MISSING = 'Message Missing';
class ExtendedError extends Error {
    constructor(props, log = ExtendedError.logOnCreation) {
        try {
            super(props.message);
            const defaultProps = {
                severity: 'HIGH',
                code: CODE_MISSING,
                info: {},
                message: MESSAGE_MISSING,
                name: ExtendedError.errorName,
            };
            const { severity, ...rest } = props;
            Object.assign(this, defaultProps, rest, { severity });
            if (log)
                this.log();
        }
        catch (error) {
            throw new ExtendedError({
                code: 'EXTENDED_ERROR_CONSTRUCT_ERROR',
                message: 'Unable to construct extended error',
                severity: 'HIGH',
            });
        }
    }
    static transform(error, defaultProps, log = ExtendedError.logOnCreation) {
        if (error instanceof ExtendedError) {
            error.addInfo(defaultProps.info);
            return error;
        }
        if (error instanceof Error) {
            const { name, message, stack, ...customProps } = error;
            const parentError = { name, message, ...customProps };
            return new ExtendedError({
                message,
                stack,
                ...defaultProps,
                info: { parentError, ...defaultProps.info },
            }, log);
        }
        return new ExtendedError({
            ...defaultProps,
            info: { parentError: error, ...defaultProps.info },
        }, log);
    }
    static JSONparse(JSONstring, returnError) {
        try {
            return JSON.parse(JSONstring, this.JSONreviver);
        }
        catch (error) {
            const parseError = ExtendedError.transform(error, {
                code: 'PARSE_ERROR',
                message: 'Unable to parse string',
                severity: 'HIGH',
                info: { JSONstring },
            });
            if (returnError)
                return parseError;
            throw parseError;
        }
    }
    static JSONreviver(key, value) {
        if (typeof value === 'object' && value._JSONrev === 'EE') {
            const { _JSONrev, ...props } = value;
            return new ExtendedError(props);
        }
        return value;
    }
    get handled() {
        return this.severity === 'HANDLED';
    }
    addInfo(info) {
        if (info)
            Object.assign(this.info, info);
        return this;
    }
    changeSeverity(newSeverity) {
        this.severity = newSeverity;
        return this;
    }
    handle(handledInfo) {
        if (this.severity === 'HANDLED') {
            return false;
        }
        this.severity = 'HANDLED';
        if (handledInfo)
            this.info.handledInfo = handledInfo;
        console.log('Handled: ' + this.toString());
        return true;
    }
    log(message = this.print()) {
        switch (this.severity) {
            case 'HIGH':
            case 'MEDIUM':
                console.error(message);
                break;
            case 'LOW':
                console.warn(message);
                break;
            default:
            case 'LOW':
                console.log(message);
        }
        return this;
    }
    print() {
        const border = '\n---------------------------------------------------------------\n';
        const name = this.toString();
        const info = Object.keys(this.info).length
            ? '\nInfo: ' + JSON.stringify(this.info, null, 2)
            : '';
        const trace = this.stack.substring(this.stack.indexOf('\n') + 1);
        const contents = name + '\n' + this.message + info + '\n' + trace;
        return border + contents + border;
    }
    toObject() {
        return {
            name: this.name,
            code: this.code,
            message: this.message,
            severity: this.severity,
            info: this.info,
            stack: this.stack,
        };
    }
    toJSON() {
        return {
            ...this.toObject(),
            _JSONrev: 'EE',
        };
    }
    toString() {
        return `${this.name} - ${this.code} (${this.severity})`;
    }
}
exports.default = ExtendedError;
ExtendedError.errorName = 'Extended Error';
ExtendedError.logOnCreation = true;
