import { deepClone } from '../helpers';
export class StateCache {
    constructor(defaultState) {
        this._initialise(defaultState);
    }
    _initialise(state) {
        this.default = state;
        this.current = deepClone(state);
        this.prev = {};
    }
    reset(resetData) {
        this.prev = this.current;
        this.current = resetData || deepClone(this.default);
    }
    updateProp(key, newValue) {
        const { [key]: currentValue } = this.current;
        if (currentValue === newValue) {
            return false;
        }
        this.prev[key] = currentValue;
        this.current[key] = newValue;
        return true;
    }
}
