import { deepClone } from '../helpers';
import { StateCache } from '../SharedState/StateCache';
export class MapCache extends StateCache {
    constructor(defaultElements, key) {
        super({});
        this.defaultElements = defaultElements;
        this.key = key;
        this.add(defaultElements);
        this.default = deepClone(this.current);
    }
    add(newElements) {
        const updatedElements = {};
        newElements.forEach((element) => {
            if (super.updateProp(element[this.key], element)) {
                updatedElements[element[this.key]] = element;
            }
        });
        return updatedElements;
    }
    remove(removeElements) {
        let updated = false;
        removeElements.forEach((key) => {
            if (super.updateProp(key, undefined)) {
                updated = true;
            }
        });
        return updated;
    }
}
