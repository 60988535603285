import { AppState } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
//@ts-ignore
import CryptoJS from 'react-native-crypto-js';
import ExtendedError from 'extended_err';
export class StorageHandler {
    constructor(stateCache, options) {
        this.options = options;
        this.stateCache = stateCache;
        if (options.saveOnBackground) {
            AppState.addEventListener('change', this.handleAppBackgrounded.bind(this));
        }
    }
    async get() {
        try {
            let stateString = await AsyncStorage.getItem(this.options.storeName);
            if (this.options.encryptionKey && stateString) {
                stateString = CryptoJS.AES.decrypt(stateString, this.options.encryptionKey);
                // @ts-ignore
                stateString = stateString.toString(CryptoJS.enc.Utf8);
            }
            return JSON.parse(stateString, this.options.reviver);
        }
        catch (error) {
            throw ExtendedError.transform(error, {
                name: 'State Error',
                code: 'STORAGE_ERROR',
                message: 'Error loading from storage',
                severity: 'HIGH',
            });
        }
    }
    async reset(resetData) {
        resetData
            ? await this.save(resetData)
            : await AsyncStorage.removeItem(this.options.storeName);
    }
    async save(saveData) {
        try {
            let stateString = JSON.stringify(saveData || this.stateCache.current, this.options.replacer);
            if (this.options.encryptionKey) {
                stateString = CryptoJS.AES.encrypt(stateString, this.options.encryptionKey).toString();
            }
            await AsyncStorage.setItem(this.options.storeName, stateString);
            return true;
        }
        catch (error) {
            ExtendedError.transform(error, {
                name: 'State Error',
                code: 'STORAGE_SAVE_ERROR',
                message: 'Unable to save state',
                info: { storeName: this.options.storeName },
                severity: 'HIGH',
            });
            return false;
        }
    }
    handleAppBackgrounded(nextAppState) {
        // When the app switches to background the current state is saved
        if (nextAppState === 'background') {
            this.save();
        }
    }
}
