import { toArray } from '../helpers';
export class ComponentRegister {
    constructor() {
        this.map = new Map();
    }
    register(registerKey, updateKeys, reRenderComponent) {
        const updateKeysArray = toArray(updateKeys);
        function onUpdate(updateProps) {
            if (updateProps === true ||
                Object.keys(updateProps).some(key => updateKeysArray.includes(key))) {
                reRenderComponent();
            }
        }
        this.map.set(registerKey, onUpdate);
    }
    update(updateProps) {
        this.map.forEach(onUpdate => onUpdate(updateProps));
    }
    unregister(registerKey) {
        this.map.delete(registerKey);
    }
}
